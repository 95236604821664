import React from 'react';
import Paragraph from '../Paragraph';

const Intro = () => {
  return (
    <Paragraph>
      Welcome to my space. Let me tell you about some of the stuff I have worked
      on.
    </Paragraph>
  );
};

export default Intro;
